import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-icon-selector',
    templateUrl: './icon-selector.component.html',
    styleUrls: ['./icon-selector.component.scss']
})
export class IconSelectorComponent implements OnInit {
    public icons: Observable<string[]>;
    @Output() iconSelected = new EventEmitter<string>();

    constructor(private http: HttpClient) {}

    ngOnInit() {
        this.icons = this.http.get<string[]>('assets/provider-service-icons/icons.json');
    }

    selectIcon(icon: string) {
        this.iconSelected.emit(icon);
    }
}
