import * as Types from '../generated/graphql-schema';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ProUserProfileQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ProUserProfileQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, display_name?: string | null, email?: string | null, phone_number?: string | null } | null };

export const ProUserProfileDocument = gql`
    query ProUserProfile {
  me {
    id
    display_name
    email
    phone_number
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProUserProfileGQL extends Apollo.Query<ProUserProfileQuery, ProUserProfileQueryVariables> {
    document = ProUserProfileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }