// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttons {
  display: flex;
  justify-content: space-between;
  max-width: 350px;
  margin: var(--ion-margin) auto;
  gap: var(--half-ion-padding);
}
.buttons ion-button {
  flex-grow: 1;
}

.cancel-fee-options-group {
  display: flex;
  flex-direction: column;
  margin: var(--ion-padding) 0;
}
.cancel-fee-options-group .partial-fee-input {
  max-width: 100px;
}
.cancel-fee-options-group .partial-fee-input:before {
  content: "\$";
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNhbmNlbC1jb25maXJtYXRpb24uY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxhQUFBO0VBQ0EsOEJBQUE7RUFDQSxnQkFBQTtFQUNBLDhCQUFBO0VBQ0EsNEJBQUE7QUFDRjtBQUNFO0VBQ0UsWUFBQTtBQUNKOztBQUdBO0VBQ0UsYUFBQTtFQUNBLHNCQUFBO0VBQ0EsNEJBQUE7QUFBRjtBQUVFO0VBQ0UsZ0JBQUE7QUFBSjtBQUVJO0VBQ0UsWUFBQTtBQUFOIiwiZmlsZSI6ImNhbmNlbC1jb25maXJtYXRpb24uY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuYnV0dG9ucyB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2VlbjtcbiAgbWF4LXdpZHRoOiAzNTBweDtcbiAgbWFyZ2luOiB2YXIoLS1pb24tbWFyZ2luKSBhdXRvO1xuICBnYXA6IHZhcigtLWhhbGYtaW9uLXBhZGRpbmcpO1xuXG4gIGlvbi1idXR0b24ge1xuICAgIGZsZXgtZ3JvdzogMTtcbiAgfVxufVxuXG4uY2FuY2VsLWZlZS1vcHRpb25zLWdyb3VwIHtcbiAgZGlzcGxheTogZmxleDtcbiAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgbWFyZ2luOiB2YXIoLS1pb24tcGFkZGluZykgMDtcblxuICAucGFydGlhbC1mZWUtaW5wdXQge1xuICAgIG1heC13aWR0aDogMTAwcHg7XG5cbiAgICAmOmJlZm9yZSB7XG4gICAgICBjb250ZW50OiBcIiRcIjtcbiAgICB9XG4gIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/reservation-details/cancel-confirmation/cancel-confirmation.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,gBAAA;EACA,8BAAA;EACA,4BAAA;AACF;AACE;EACE,YAAA;AACJ;;AAGA;EACE,aAAA;EACA,sBAAA;EACA,4BAAA;AAAF;AAEE;EACE,gBAAA;AAAJ;AAEI;EACE,YAAA;AAAN;AACA,wgCAAwgC","sourcesContent":[".buttons {\n  display: flex;\n  justify-content: space-between;\n  max-width: 350px;\n  margin: var(--ion-margin) auto;\n  gap: var(--half-ion-padding);\n\n  ion-button {\n    flex-grow: 1;\n  }\n}\n\n.cancel-fee-options-group {\n  display: flex;\n  flex-direction: column;\n  margin: var(--ion-padding) 0;\n\n  .partial-fee-input {\n    max-width: 100px;\n\n    &:before {\n      content: \"$\";\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
